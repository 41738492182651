import { useState } from "react";
import { navigation } from "@/constants/navigation";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";

type SideBarProps = {
    setDrawerVisible: (status: boolean) => void;
};

export const SideBar = ({ setDrawerVisible }: SideBarProps) => {
    const location = useLocation();
    const active = location?.pathname?.split("/").join(",/").split(",");
    const [navigationList, _] = useState<any[]>(navigation);

    return (
        <ul className="side-bar__nav__group dashboard_menu !pl-0">
            {navigationList.map((i: any, index) => (
                <li
                    className={
                        "side-bar__nav__item" +
                        (active?.indexOf(i.url) !== -1
                            ? " side-bar__nav__item--active"
                            : "")
                    }
                    key={`side-bar__nav__item-${index}`}
                >
                    <NavLink
                        key={i.url}
                        onClick={() => setDrawerVisible(false)}
                        to={i.url}
                    >
                        {i.icon && (
                            <i.icon
                                className={`side-bar__nav__item__icon${
                                    i.label === "Devices" ? "-stroke" : ""
                                }`}
                            />
                        )}
                        <span className="item-label">{i.label}</span>
                    </NavLink>
                    {i.subItems && (
                        <ul
                            className={
                                active?.indexOf(i.url) !== -1
                                    ? " nav--expanded"
                                    : ""
                            }
                        >
                            {i.subItems.map((subItem: any, index: number) => {
                                let match = false;
                                subItem?.routes?.forEach((routeURL: any) => {
                                    const isMatch =
                                        // eslint-disable-next-line
                                        useRouteMatch({
                                            path: routeURL,
                                            exact: true,
                                        });
                                    if (isMatch) match = true;
                                });

                                return (
                                    <li
                                        key={index}
                                        className={
                                            "side-bar__nav__sub_item" +
                                            (match
                                                ? " side-bar__nav__sub_item--active"
                                                : "")
                                        }
                                    >
                                        <NavLink
                                            onClick={() =>
                                                setDrawerVisible(false)
                                            }
                                            to={subItem.url}
                                        >
                                            <span className="item-label">
                                                {subItem.label}
                                            </span>
                                        </NavLink>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </li>
            ))}
        </ul>
    );
};
