export const EVENTS_ROUTE = "/events";
export const EVENT_DETAILS = (UUID = ":uuid") => `${EVENTS_ROUTE}/${UUID}`;
export const DASHBOARD_ROUTE = "/dashboard";
export const CUSTOMERS_ROUTE = "/customers";
export const CUSTOMERS_DETAILS = (UUID = ":uuid") =>
    `${CUSTOMERS_ROUTE}/${UUID}`;
export const DEVICES_ROUTE = "/devices";
export const DEVICES_DETAILS = (UUID = ":uuid") => `${DEVICES_ROUTE}/${UUID}`;
export const LISTS_ROUTE = "/lists";
export const LISTS_DETAILS_ROUTE = (UUID = ":uuid") => `${LISTS_ROUTE}/${UUID}`;
export const SETTINGS_ROUTE = "/settings";
export const RULES_VIEW = `/settings/rules-view`;
export const DEVICE_SCORING = `/settings/device-scoring`;
export const TEAM_MANAGEMENT = `/settings/team-management`;
export const API_KEYS_MANAGEMENT = `/settings/api-keys`;
export const REPORTS = `/settings/reports`;
export const AUDIT_TRAIL = `/settings/audit-trail`;
export const ACCOUNT_SETTINGS = `/settings/account-settings`;
export const THRESHOLD = `/settings/threshold`;
export const RULES = `/settings/rules`;
export const NOT_FOUND_ROUTE = "/404";

export const FOCAL_ROUTE_STAGING = "https://staging.sa.focal.mozn.sa";
export const FOCAL_ROUTE_PROD = "https://app.sa.focal.mozn.sa";
